<template>
  <aside
    class="left-sidebar"
    :class="
      Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`
    "
  >
    <div :class="`bg-${logoColor} navbar-header`">
      <img src="/geosee-logo.svg" style="width: 80%;"/>
      <span
        class="d-block d-lg-none close-sidebar-btn"
        @click="showMobileSidebar"
        ><i class="mdi mdi-close font-18"></i
      ></span>
    </div>

    <div class="scrlbar">
      <ul class="nav flex-column mb-0">
        <template v-for="(route, i) in routes">
          <!---Single Menu -->
          <li class="nav-item" :key="i" v-if="!route.childern && !route.header">
            <a class="nav-link d-flex" :href="`${route.to}`"><i :class="route.icon"></i> &nbsp; {{ route.title }}</a>
            <!-- <router-link :to="`${route.to}`" class="nav-link d-flex">
              <i :class="route.icon"></i>
              <span class="hide-text">{{ route.title }}</span>
            </router-link> -->
          </li>
        </template>
        <li class="nav-item">
          <a class="nav-link d-flex" v-b-toggle.collapse-1 ><i class="mdi mdi-flash"></i> &nbsp; Produzione energetica &nbsp; <i class="mdi mdi-chevron-down"></i> </a>
        </li>

        <b-collapse id="collapse-1" class="mt-2 nav-item">
          <a class="nav-link d-flex" :href="`/produzione-energetica-eolica`"><i class="mdi mdi-weather-windy"></i> &nbsp; Energia eolica</a>
          <a class="nav-link d-flex" :href="`/produzione-energetica-solare`"><i class="mdi mdi-weather-sunny"></i> &nbsp; Energia solare</a>
        </b-collapse>
      </ul>
      <!-- <div class="text-center px-3">
        <b-button
          target="_blank"
          variant="info"
          block
          href="https://www.wrappixel.com/templates/xtreme-vuesax-admin-pro/"
          >Upgrade to Pro</b-button
        >
      </div> -->
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import SidebarData from "./SidebarData";

export default {
  name: "VerticalSidebar",
  components: {
  },
  data: () => ({
    initialShow: true,
    routes: SidebarData,
  }),
  computed: {
    ...mapState(["logoColor", "Sidebar_drawer", "SidebarColor"]),
    check() {
      return console.log(this.$router.history.current);
    },
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", false);
    },
  },
};
</script>

<style>
#dd__BV_toggle_{
  background: transparent !important;
  color: #212529;
}
</style>