export default [
  {
    id: "1",
    icon: "mdi mdi-view-dashboard",
    title: "Dashboard",
    to: "/",
  },

  {
    id: "2",
    icon: "mdi mdi-weather-pouring",
    title: "Rischio alluvioni",
    to: "/rischio-alluvioni",
  },
  {
    id: "4",
    icon: "mdi mdi-fire",
    title: "Rischio incendi",
    to: "/rischio-incendi",
  },
  {
    id: "5",
    icon: "mdi mdi-file-video",
    title: "Video trappole",
    to: "/video-trappole",
  },
  {
    id: "6",
    icon: "mdi mdi-domain",
    title: "Edifici non accatastati",
    to: "/edifici-non-accatastati",
  }
];
